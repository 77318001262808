import request, { baseUrl } from "@/utils/request";

/*
 *@描述:  获取地址列表
 *@参数: 无
 *@作者: 金涛
 *@日期: 2021-12-16 16:09:17
 */
export function getAddressList(data) {
  return request({
    url: `${baseUrl.api}/receiveAddress/getAddressList`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

/*
 *@描述: 新增收货地址
 *@参数: data{
  receiveName: "", // 收货人姓名
  receivePhone: "", // 收货人手机号
  countryId: "", // 收货国家id
  countryName: "", // 收货国家名称
  provinceId: "", // 收货省id
  provinceName: "", // 收货省名称
  cityId: "", // 收货市id
  cityName: "", // 收货市名称
  addressDetail: "", // 收货地址详情
 }
 *@作者: 金涛
 *@日期: 2021-12-16 19:20:50
 */
export function addAddress(data) {
  return request({
    url: `${baseUrl.api}/receiveAddress/addAddress`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

/*
 *@描述: 删除地址
 *@参数: id: 删除地址的id
 *@作者: 金涛
 *@日期: 2021-12-16 21:20:40
 */
export function deleteAddress(id) {
  return request({
    url: `${baseUrl.api}/receiveAddress/deleteAddress/${id}`,
    method: "POST",
    headers: {
      needToken: true,
    },
  });
}

/**
 * 设置默认收货地址
 * @param {*} id
 * @returns
 */
export function setDefaultAddress(id) {
  return request({
    url: `${baseUrl.api}/receiveAddress/setDefaultAddress/${id}`,
    method: "POST",
    headers: {
      needToken: true,
    },
  });
}

<template>
  <van-dialog
    v-model="show"
    :title="title"
    :show-cancel-button="showCancelButton"
    :showConfirmButton="showConfirmButton"
    :confirmButtonText="confirmButtonText"
    :cancelButtonText="cancelButtonText"
    :confirmButtonColor="confirmButtonColor"
    :cancelButtonColor="cancelButtonColor"
    @confirm="confirm"
    @cancel="cancel"
  >
    <slot />
    <van-icon name="close" color="#ffffff" size="0.44rem" @click="cancel" />
  </van-dialog>
</template>
<script>
export default {
  name: "ConfirmProp",
  props: {
    // 弹窗的标题默认（标题）
    title: {
      type: String,
      default: "标题",
    },

    // 是否展示取消按钮默认（不展示false）
    showCancelButton: {
      type: Boolean,
      default: false,
    },

    // 是否展示确认按钮默认（展示true）
    showConfirmButton: {
      type: Boolean,
      default: true,
    },

    // 确认按钮文案默认（确认）
    confirmButtonText: {
      type: String,
      default: "确认",
    },

    // 取消按钮文案默认（取消）
    cancelButtonText: {
      type: String,
      default: "取消",
    },

    // 确认按钮文字颜色默认（#FFFFFF）
    confirmButtonColor: {
      type: String,
      default: "#FFFFFF",
    },

    // 取消按钮文字颜色默认（#D1B06F）
    cancelButtonColor: {
      type: String,
      default: "#D1B06F",
    },

    // 是否展示弹窗背景
    overlay: {
      type: Boolean,
      default: true,
    },

    // 弹窗背景样式
    overlayStyle: {
      type: Object,
      default: function () {
        return {
          background: "rgba(0, 0, 0, 0.5)",
        };
      },
    },

    // 是否展示弹窗
    showConfirm: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showConfirm(newVal) {
      this.show = newVal;
    },
  },
  data() {
    return {
      // 弹窗状态
      show: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.van-dialog {
  background: transparent;
  padding-bottom: 0.4rem;

  /deep/ {
    .van-dialog__header,
    .van-dialog__content,
    .van-dialog__footer {
      background: #fbf7f3;
    }

    .van-dialog__header {
      font-size: 0.36rem;
    }

    .van-dialog__content {
      word-break: break-all;
    }

    .van-dialog__footer {
      background: #fbf7f3;
      margin: 0 auto 0.48rem;
      padding: 0.3rem 8% 0.48rem;
      border-radius: 0 0 0.36rem 0.36rem;
    }

    .van-dialog__cancel {
      height: 0.9rem;
      background: #fbf7f3;
      border: 0.02rem solid #d1b06f;
      border-radius: 0.48rem;
      margin-right: 0.32rem;
    }

    .van-dialog__confirm {
      height: 0.9rem;
      background: #d1b06f;
      border-radius: 0.48rem;
      box-shadow: 0 0.08rem 0.16rem 0 rgba(150, 134, 102, 0.7);
      border: 0.02rem solid #d1b06f;
    }

    .van-hairline--top::after {
      display: none;
    }

    .van-icon-close {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 50%;
    }
  }
}
</style>

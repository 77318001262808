<template>
  <div style="display: relative">
    <van-nav-bar
      :title="title"
      :left-text="$t('returnPre')"
      left-arrow
      @click-left="onClickLeft"
      fixed
    />
    <div style="height: 46px"></div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-nav-bar__text {
  color: #755a24 !important;
}
/deep/ .van-nav-bar .van-icon {
  color: #755a24 !important;
}
</style>

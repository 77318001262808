<template>
  <div class="container">
    <NavBar :title="$t('route.shoppingAddress')" />
    <div v-if="isFinish" style="height: 100%">
      <template v-if="addressList.length">
        <van-swipe-cell
          v-for="(item, index) in addressList"
          :key="index"
          @open="open(item.isDefaultAddress === 1 ? false : true, item.id)"
          @close="close"
          :before-close="beforeClose"
        >
          <div
            class="u-flex-col address-list"
            @click.stop="selectAddress(item)"
          >
            <div class="u-flex u-row-between">
              <div class="u-flex u-row-between">
                <h4 class="user-name">{{ item.receiveName }}</h4>
                <span>{{ item.receivePhone }}</span>
              </div>
              <span :class="item.isDefaultAddress === 1 ? 'active' : ''">
                {{ item.isDefaultAddress === 1 ? $t("chosen") : "" }}
              </span>
            </div>
            <p class="address-info">
              {{
                `${item.countryName}${item.provinceName}${item.cityName}${item.areaName}${item.addressDetail}`
              }}
            </p>
          </div>
          <template #right>
            <van-icon name="delete-o" size="0.48rem" color="#ffffff" />
          </template>
        </van-swipe-cell>
      </template>

      <EmptyTip v-else>
        <span class="empty-tip">{{ $t("emptyAddress") }}</span>
      </EmptyTip>
    </div>

    <!-- 添加收货地址 -->
    <div class="submit-btn u-flex u-row-center">
      <buttons @click.native="handleSubmit">
        {{ $t("addShippingAddress") }}
      </buttons>
    </div>

    <!-- 删除二次确认的弹窗 -->
    <confirm-prop
      :showCancelButton="true"
      :confirmButtonText="$t('delete')"
      :title="$t('deleteAddress')"
      :showConfirm="isDelete"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
      <p class="slot-tips">{{ $t("confirmDeleteAddress") }}</p>
    </confirm-prop>

    <!-- 新增地址的弹窗 -->
    <van-popup
      v-model="isAdd"
      round
      position="bottom"
      closeable
      close-icon="cross"
      safe-area-inset-bottom
    >
      <div class="add-address">
        <h3 class="address-title">{{ $t("addShippingAddress") }}</h3>
        <van-form validate-first @submit="onSubmit">
          <!-- 收货人姓名 -->
          <van-field
            v-model="addAddressForm.receiveName"
            :label="$t('receiver')"
            :placeholder="$t('inputReceiver')"
            input-align="right"
            :rules="addAddressRules.receiveName"
            error-message-align="right"
            center
            clearable
          />

          <!-- 手机号码 -->
          <van-field
            v-model="addAddressForm.receivePhone"
            type="tel"
            :label="$t('mobile')"
            :placeholder="$t('inputMobile')"
            input-align="right"
            :rules="addAddressRules.receivePhone"
            error-message-align="right"
            center
            clearable
          />

          <!-- 所在地区 -->
          <van-field
            v-model="addressInfo"
            :label="$t('area')"
            :placeholder="$t('pleaseChoose')"
            input-align="right"
            right-icon="arrow"
            :rules="addAddressRules.addressInfo"
            error-message-align="right"
            center
            @click="chooseProvince"
            readonly
          />

          <!-- 详细地址 -->
          <van-field
            v-model="addAddressForm.addressDetail"
            :label="$t('address')"
            :placeholder="$t('inputAddress')"
            input-align="right"
            :rules="addAddressRules.addressDetail"
            error-message-align="right"
            center
            clearable
          />

          <!-- 确定 -->
          <div style="margin: 0.48rem 0.4rem 0.6rem">
            <buttons native-type="submit">{{ $t("sure") }}</buttons>
          </div>
        </van-form>
      </div>
    </van-popup>

    <!-- 地址三级联动弹窗 -->
    <AreaAddress :showArea="showArea" @close="closeArea" />
  </div>
</template>
<script>
import Buttons from "@/components/Buttons";
import ConfirmProp from "@/components/ConfirmProp";
import AreaAddress from "@/components/AreaAddress";
import EmptyTip from "@/components/EmptyTip";
import NavBar from "@/components/NavBar";
import {
  getAddressList,
  addAddress,
  deleteAddress,
  setDefaultAddress,
} from "@/api/shippingAddress";

export default {
  name: "shippingAddress",
  components: {
    Buttons,
    ConfirmProp,
    AreaAddress,
    EmptyTip,
    NavBar,
  },
  data() {
    return {
      // 地址列表是否加载完成
      isFinish: false,

      // 获取地址列表的请求参数
      getAddressObj: {
        pageNum: 1,
        pageSize: 10,
      },

      // 标记此地址是否允许被删除
      isAllowDeleted: undefined,

      // 地址集合
      addressList: [],

      // 删除弹窗是否显示
      isDelete: false,

      // 是否展示新增地址弹窗
      isAdd: false,

      // 是否展示地址三级联动弹窗
      showArea: false,

      // 添加地址的表单提交项
      addAddressForm: {
        receiveName: "", // 收货人姓名
        receivePhone: "", // 收货人手机号
        countryId: "", // 收货国家id
        countryName: "", // 收货国家名称
        provinceId: "", // 收货省id
        provinceName: "", // 收货省名称
        cityId: "", // 收货市id
        cityName: "", // 收货市名称
        addressDetail: "", // 收货地址详情
      },

      // 添加国家省市对象字段
      addressInfo: "",

      // 添加地址表单校验项
      addAddressRules: {
        receiveName: [{ required: true, message: this.$t("inputReceiver") }],
        receivePhone: [{ required: true, message: this.$t("inputMobile") }],
        addressInfo: [{ required: true, message: this.$t("selectRegion") }],
        addressDetail: [{ required: true, message: this.$t("inputAddress") }],
      },

      // 滑动单元格对象
      instance: null,

      // 删除地址的请求参数
      deleteAddressId: undefined,
    };
  },
  created() {
    this.getAddressList();
  },
  methods: {
    onClickLeft() {
      console.log("eeee");
    },
    // 查询地址列表
    getAddressList(flag) {
      this.isFinish = false;
      getAddressList(this.getAddressObj).then((res) => {
        if (res.code === 200) {
          this.isFinish = true;
          this.addressList = res.data.list;

          if (flag) {
            let selectAddrObj = this.addressList.find((item) => {
              if (item.isDefaultAddress === 1) {
                return item;
              }
            });

            /**
             * todo(调用客户端设置默认地址)
             * 收货地址id
             * 收货人姓名
             * 收货人联系方式
             * 收货地址
             */
            const receiveAddress = `${selectAddrObj.countryName}${selectAddrObj.provinceName}${selectAddrObj.cityName}${selectAddrObj.areaName}${selectAddrObj.addressDetail}`;
            this.handleSelectedAddr(
              selectAddrObj.id,
              selectAddrObj.receiveName,
              selectAddrObj.receivePhone,
              receiveAddress
            );
          }
        }
      });
    },

    // 选择默认地址
    selectAddress(items) {
      // 设置默认地址接口
      setDefaultAddress(items.id).then(() => {
        this.addressList.map((item) => {
          if (items.id === item.id) {
            item.isDefaultAddress = 1;
          } else {
            item.isDefaultAddress = 2;
          }
        });

        /**
         * todo(调用客户端设置默认地址)
         * 收货地址id
         * 收货人姓名
         * 收货人联系方式
         * 收货地址
         */
        const receiveAddress = `${items.countryName}${items.provinceName}${items.cityName}${items.areaName}${items.addressDetail}`;
        this.handleSelectedAddr(
          items.id,
          items.receiveName,
          items.receivePhone,
          receiveAddress
        );
      });
    },

    // 监听左滑打开的动作
    open(flag, deleteId) {
      this.isAllowDeleted = flag;
      this.deleteAddressId = deleteId;
    },

    // 监听左滑关闭的动作
    close() {
      this.isAllowDeleted = undefined;
      this.deleteAddressId = undefined;
      this.instance = null;
    },

    // 监听滑动内容操作的动作
    beforeClose({ position, instance }) {
      this.instance = instance;
      if (position === "right") {
        if (this.isAllowDeleted) {
          this.isDelete = true;
        } else {
          this.$toast.fail(this.$t("defaultAddressnotDeleted"));
          instance.close();
          this.deleteAddressId = undefined;
        }
      } else {
        if (!this.isDelete) {
          instance.close();
          this.deleteAddressId = undefined;
        }
      }
    },

    // 监听二次删除弹窗的删除按钮点击
    handleConfirm() {
      deleteAddress(this.deleteAddressId).then((res) => {
        if (res.code === 200) {
          this.instance.close();
          this.instance = null;
          this.isDelete = false;
          this.addressList = [];
          this.getAddressList();
        }
      });
    },

    // 监听二次确认弹窗的取消按钮点击
    handleCancel() {
      this.instance.close();
      this.instance = null;
      this.isDelete = false;
      this.deleteAddressId = undefined;
    },

    // 选择三级联动地址
    chooseProvince() {
      this.showArea = true;
      this.isAdd = false;
    },

    // 添加收货地址
    handleSubmit() {
      if (this.addressList.length >= 10) {
        this.$toast.fail(this.$t("upTo10"));
      } else {
        this.isAdd = true;
      }
    },

    // 新增地址的表单提交
    onSubmit() {
      addAddress(this.addAddressForm).then((res) => {
        if (res.code === 200) {
          this.isAdd = false;
          this.addressInfo = "";
          this.addressList = [];
          this.getAddressList(1);
        }
      });
    },

    // 监听地址三级联动弹窗收起
    closeArea(country, province, city) {
      this.isAdd = true;
      this.showArea = false;

      // 国家省市三级联动必须三项都选择
      if (!(country && province && city)) {
        this.$toast.fail(this.$t("pleaseSelectRegion"));
      } else {
        let countryName = country.name,
          countryId = country.id,
          provinceName = province.name,
          provinceId = province.id,
          cityName = city.name,
          cityId = city.id;

        this.addressInfo = `${countryName}-${provinceName}-${cityName}`;
        this.addAddressForm.countryName = countryName;
        this.addAddressForm.countryId = countryId;
        this.addAddressForm.provinceName = provinceName;
        this.addAddressForm.provinceId = provinceId;
        this.addAddressForm.cityName = cityName;
        this.addAddressForm.cityId = cityId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background: #fbf7f3;
  padding: 0.4rem 0.4rem 1.6rem;
}

.address-list {
  font-size: 0.28rem;
  font-weight: 400;
  text-align: justify;
  color: #515151;
  padding: 0.3rem 0.24rem 0.24rem;
  background: #fefdfc;
  border-radius: 0.08rem;
  margin-bottom: 0.4rem;
}

.user-name {
  font-size: 0.32rem;
  font-weight: 600;
  margin-right: 0.12rem;
}

.active {
  display: block;
  background: #c69636;
  border-radius: 2rem 0 0 2rem;
  font-size: 0.24rem;
  color: #ffffff;
  padding: 0.02rem 0.16rem 0.02rem 0.24rem;
}

.address-info {
  word-break: break-all;
  margin-top: 0.15rem;
}

.submit-btn {
  height: 1.6rem;
  padding: 0 0.48rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fbf7f3;
}

.slot-tips {
  line-height: 1.2rem;
  font-size: 0.28rem;
  font-weight: 400;
  text-align: justify;
  color: #afafaf;
  text-align: center;
  margin: -0.03rem 0;
}

.add-address {
  padding-top: 0.4rem;

  .van-cell {
    padding: 0.36rem 0.4rem;
  }

  /deep/ {
    .van-cell::after {
      display: none;
    }

    .van-field__label {
      font-size: 0.28rem;
      font-weight: 500;
      text-align: justify;
      color: #515151;
    }

    .van-field__error-message {
      height: 0;
      color: #d1b06f;
    }

    .van-field--error .van-field__control,
    .van-field--error .van-field__control::placeholder {
      color: #d1b06f;
    }
    .van-field__control::placeholder {
      font-size: 0.28rem;
    }
  }
}

.address-title {
  font-size: 0.32rem;
  font-weight: 600;
  color: #755a24;
  padding: 0 0.4rem 0.2rem;
  border-bottom: 0.04rem solid rgba(151, 151, 151, 0.12);
  margin-bottom: 0.18rem;
}

.empty-tip {
  opacity: 0.6;
  font-size: 0.28rem;
  color: rgba(81, 81, 81, 0.6);
}

/deep/ {
  .van-swipe-cell__right {
    width: 0.96rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2644d;
  }

  .van-popup__close-icon--top-right {
    font-size: 0.4rem;
    color: #515151;
  }

  .van-field__right-icon {
    padding: 0;
  }

  .van-icon-arrow {
    color: #000000;
    font-size: 0.25rem;
  }
}
</style>
